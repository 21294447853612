import {Controller} from "stimulus"
import MicroModal from "micromodal"
import {maskInput} from "vanilla-text-mask"
import {errorTexts, badNameError, badEmailError, unidentifiedError} from "controllers-shared"
import {setError, clearErrors, resetErrorTargets, resetInputTargets, setFocus, toggleSubmitButton, updateStatusView} from "wellness/dom-utils"

export default class extends Controller {
  static get targets() {
    return ["form", "name", "nameError", "email", "emailError", "phone", "phoneError", "offerId", "sponsorId", "submit", "entry", "successView", "errorView"]
  }

  connect() {
    MicroModal.init({
      disableScroll: true,
      onShow: () => setFocus(this.nameTarget), // Focus on first input
      onClose: () => this.onModalClose()
    })

    if (this.phoneTarget) {
      const phoneMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
      maskInput({
        inputElement: this.phoneTarget,
        mask: phoneMask
      })
    }
  }

  // store original submit button text
  buttonText = this.submitTarget.innerHTML

  errorDictionary() {
    return {
      "lead/bad-name": {
        inputTarget: this.nameTarget,
        errorTarget: this.nameErrorTarget,
        message: errorTexts[badNameError]
      },
      "lead/bad-email": {
        inputTarget: this.emailTarget,
        errorTarget: this.emailErrorTarget,
        message: errorTexts[badEmailError]
      }
    }
  }

  revealError(error) {
    const errorObj = this.errorDictionary()[error]
    if (errorObj) {
      setError(errorObj)
      setFocus(errorObj.inputTarget)
    }
  }

  inputTargets = [this.nameTarget, this.emailTarget, this.phoneTarget]
  errorTargets = [this.nameErrorTarget, this.emailErrorTarget, this.phoneErrorTarget]

  resetForm() {
    resetErrorTargets(this.errorTargets)
    resetInputTargets(this.inputTargets)
  }

  onModalClose() {
    this.resetForm()
    this.successViewTarget.style.display = "none"
    this.errorViewTarget.style.display = "none"
    this.entryTarget.style.display = "block"
  }

  onSubmit = async (e) => {
    e.preventDefault()

    // Prevent multiple form submissions
    if (this.submitTarget.disabled) return

    const formTarget = this.formTarget
    const submitTarget = this.submitTarget
    const entryView = this.entryTarget
    const successView = this.successViewTarget
    const errorView = this.errorViewTarget

    // Extract form data
    const lead = {
      "name": this.nameTarget.value.trim(),
      "email": this.emailTarget.value.trim(),
      "phone": this.phoneTarget.value.trim(),
      "offer-id": this.offerIdTarget.value,
      "sponsor-id": this.sponsorIdTarget.value
    }

    // Clear errors for fresh validation
    clearErrors(this.errorTargets, this.inputTargets)

    // Disable submit button and show loading text
    toggleSubmitButton(submitTarget, true, "Submitting...", this.buttonText)

    try {
      // Send request using Fetch API
      const response = await fetch(formTarget.dataset.value, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(lead)
      })

      const textResponse = await response.text();
      const responseData = textResponse ? JSON.parse(textResponse) : {};
      
      if (!response.ok) {
        throw new Error(responseData?.["lead/error"] || "unidentifiedError")
      }

      toggleSubmitButton(submitTarget, false, "Submitting...", this.buttonText)

      // Handle different response cases
      switch (responseData?.["lead/error"]) {
        case badNameError:
          this.revealError(badNameError)
          break
        case badEmailError:
          this.revealError(badEmailError)
          break
        case unidentifiedError:
          updateStatusView(entryView, successView, errorView, "error")
          break
        default:
          updateStatusView(entryView, successView, errorView, "success")
          entryView.style.display = "none"
          successView.style.display = "block"
      }
    } catch (err) {
      console.error("Submission error:", err)
      updateStatusView(entryView, successView, errorView, "error")
    }
  }
}
