// show error and add necessary aria attributes
export const setError = ({inputTarget, errorTarget, message}) => {
  if (errorTarget) {
    // Show error message text
    errorTarget.style.display = "block"
    errorTarget.innerHTML = message

    // Add aria-invalid to input
    inputTarget.setAttribute("aria-invalid", "true")
    // Add aria-describedby to input to reference error message
    inputTarget.setAttribute("aria-describedby", errorTarget.id)
  }
}

// remove error message and hide error
export const clearErrorMessage = (errorEl) => {
  if (errorEl) {
    errorEl.innerHTML = ""
    errorEl.style.display = "none"
  }
}

// remove error related attributes
export const clearInputError = (inputEl) => {
  inputEl.removeAttribute("aria-invalid")
  inputEl.removeAttribute("aria-describedby")
}

// clear error message and necessary error related attributes
export const clearError = ({inputTarget, errorTarget}) => {
  clearErrorMessage(errorTarget)
  clearInputError(inputTarget)
}

// takes an array of error targets and removes the error message and hides the error
export const resetErrorTargets = (errorTargets) => {
  errorTargets.forEach((t) => clearErrorMessage(t))
}

// takes an array of input targets and removes the error related attributes
export const resetInputTargets = (inputTargets) => {
  inputTargets.forEach((t) => {
    clearInputError(t)
    t.checked ? (t.checked = false) : (t.value = "")
  })
}


export const clearErrors = (errorTargets, inputTargets) => {
  resetErrorTargets(errorTargets)
  inputTargets.forEach((t) => clearInputError(t))
}

// set focus on input
export const setFocus = (input) => {
  input?.focus()
}

// toggle whether to disable submit button and show loading text
export const toggleSubmitButton = (button, isLoading, loadingText = "Submitting...", defaultText = "Sign Up") => {
  if (button) {
    if (isLoading) {
      button.disabled = true
      button.innerHTML = loadingText
    } else {
      button.disabled = false
      button.innerHTML = defaultText
    }
  }
}

export const updateStatusView = (entryView, successView, errorView, status) =>{
    entryView.style.display = "none"; // Always hide entry view
  
    if (status === "success") {
      successView.style.display = "block";
      errorView.style.display = "none";
    } else if (status === "error") {
      successView.style.display = "none";
      errorView.style.display = "block";
    } else {
      // Reset to default state
      entryView.style.display = "block";
      successView.style.display = "none";
      errorView.style.display = "none";
    }
  }
