import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {

  static targets = ["start", "quiz", "question", "submit", "score", "passed", "tryAgain"]

  connect() {
    this.score = 0
    this.questionCount = this.questionTargets.length

    const quiz = this.element
    this.rewardId = quiz.dataset.rewardId
    this.userId = quiz.dataset.user
    this.passCount = quiz.dataset.passCount
  }

  onStart() {
    shared.hide(this.startTarget)
    shared.show(this.quizTarget)
  }

  increaseScore() {
    this.score = this.score + 1
  }

  decreaseScore() {
    let score = this.score
    score = score === 0 ? 0 : score - 1
  }

  updateScore() {
    this.scoreTargets.map(el => {
      el.innerHTML = this.score
    })
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  inputSelector(idx) {
    return `input[name=question-${idx + 1}]:checked`
  }

  allQuestionsAnswered() {
    const submit = this.submitTarget
    const answers = this.questionTargets.map((q, idx) => q.querySelector(this.inputSelector(idx)))
    if (answers.includes(null)) {
      this.disableSubmit()
    } else {
      submit.disabled = false
    }
  }

  sendScore() {
    fetch(`/api4/wellness/rewards/${this.rewardId}/quiz/${this.userId}/submit`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        total_correct: this.score,
        total_questions: this.questionCount
      })
    })
  }

  gradeQuiz() {
    this.questionTargets.map((q, idx) => {
      const correctAnswer = q.dataset.correctIdx
      const choices = q.querySelectorAll("li.question__choices__choice")
      const userAnswer = (q.querySelector(this.inputSelector(idx)) || {}).value
      shared.addClass(choices[correctAnswer], "correct") // style/mark the correct choice

      if (correctAnswer == userAnswer) {
        this.increaseScore() // increase score
      } else {
        this.decreaseScore() // decrease score
        shared.addClass(choices[userAnswer], "incorrect") // style/mark choice as incorrect
      }

      this.updateScore() // update score targets
    })
  }

  lockQuiz() {
    this.quizTarget.style.pointerEvents = "none"
    this.disableSubmit()
  }

  showResults() {
    if (this.score >= this.passCount) {
      shared.show(this.passedTarget)
    } else {
      shared.show(this.tryAgainTarget)
    }
  }

  onSubmit() {
    this.gradeQuiz()
    this.sendScore()
    this.lockQuiz()
    this.showResults()
  }

}
