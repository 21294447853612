import {Controller} from "stimulus"
import MicroModal from "micromodal"
import {getMetaContentByName} from "controllers-shared"
import {setError, clearErrors, resetErrorTargets, resetInputTargets, clearError, setFocus, toggleSubmitButton} from "wellness/dom-utils"
import {Constants, ERROR_MESSAGES, isEmpty, isChecked, isEmailValid, isPasswordTooShort2, isInvalidPassword, isPhoneNumberValid} from "shared/validate"

export default class extends Controller {
  static get targets() {
    return [
      "emailError",
      "email",
      "name",
      "nameError",
      "passwordError",
      "password",
      "phoneNumber",
      "phoneError",
      "state",
      "stateError",
      "agree",
      "agreeError",
      "older",
      "olderError",
      "submit",
      "loginForm",
      "loginEmail",
      "loginPassword",
      "showPhone",
      "showState"
    ]
  }

  connect() {
    console.log("rewards auth connected")

    this.inputTargets = [
      this.nameTarget,
      this.emailTarget,
      this.passwordTarget,
      ...(this.hasPhoneNumberTarget ? [this.phoneNumberTarget] : []),
      ...(this.hasStateTarget ? [this.stateTarget] : []),
      this.olderTarget,
      this.agreeTarget
    ];

    this.errorTargets = [
      this.nameErrorTarget,
      this.emailErrorTarget,
      this.passwordErrorTarget,
      ...(this.hasPhoneErrorTarget ? [this.phoneErrorTarget] : []),
      ...(this.hasStateErrorTarget ? [this.stateErrorTarget] : []),
      this.olderErrorTarget,
      this.agreeErrorTarget
    ];

    MicroModal.init({
      disableScroll: true,
      onShow: () => setFocus(this.nameTarget), // Focus on first input
      onClose: () => this.onModalClose()
    })
  }

  // source of truths for errors
  errorDictionary = {
    "empty-name": {
      inputTarget: this.nameTarget,
      errorTarget: this.nameErrorTarget,
      message: ERROR_MESSAGES.EMPTY_NAME
    },
    "empty-email": {
      inputTarget: this.emailTarget,
      errorTarget: this.emailErrorTarget,
      message: ERROR_MESSAGES.EMPTY_EMAIL
    },
    "invalid-email": {
      inputTarget: this.emailTarget,
      errorTarget: this.emailErrorTarget,
      message: ERROR_MESSAGES.INVALID_EMAIL
    },
    "non-unique-email": {
      inputTarget: this.emailTarget,
      errorTarget: this.emailErrorTarget,
      message: ERROR_MESSAGES.NON_UNIQUE_EMAIL
    },
    "short-password": {
      inputTarget: this.passwordTarget,
      errorTarget: this.passwordErrorTarget,
      message: ERROR_MESSAGES.SHORT_PASSWORD
    },
    "invalid-password": {
      inputTarget: this.passwordTarget,
      errorTarget: this.passwordErrorTarget,
      message: ERROR_MESSAGES.INVALID_PASSWORD
    },
    "empty-phone": {
      inputTarget: this.hasPhoneNumberTarget ? this.phoneNumberTarget : null,
      errorTarget: this.hasPhoneErrorTarget ? this.phoneErrorTarget : null,
      message: ERROR_MESSAGES.EMPTY_PHONE
    },
    "invalid-phone": {
      inputTarget: this.hasPhoneNumberTarget ? this.phoneNumberTarget : null,
      errorTarget: this.hasPhoneErrorTarget ? this.phoneErrorTarget : null,
      message: ERROR_MESSAGES.INVALID_PHONE
    },
    "empty-state": {
      inputTarget: this.hasStateTarget ? this.stateTarget : null,
      errorTarget: this.hasStateErrorTarget ? this.stateErrorTarget: null,
      message: ERROR_MESSAGES.EMPTY_STATE
    },
    "older-unchecked": {
      inputTarget: this.olderTarget,
      errorTarget: this.olderErrorTarget,
      message: ERROR_MESSAGES.OLDER_UNCHECKED
    },
    "agree-unchecked": {
      inputTarget: this.agreeTarget,
      errorTarget: this.agreeErrorTarget,
      message: ERROR_MESSAGES.AGREE_UNCHECKED
    },
    "try-again": {
      inputTarget: this.nameTarget,
      errorTarget: this.nameErrorTarget,
      message: ERROR_MESSAGES.TRY_AGAIN
    }
  }

  showState = this.hasShowStateTarget ? JSON.parse(this.showStateTarget.getAttribute("data-value")) : false;
  showPhone = this.hasShowPhoneTarget ? JSON.parse(this.showPhoneTarget.getAttribute("data-value")) : false;


  // Error management fns and vars

  errors = []

  resetErrors() {
    this.errors = []
  }

  getError(error) {
    return this.errorDictionary[error]
  }

  addError(error) {
    const errors = this.errors
    !errors.includes(error) && errors.push(error)
  }

  clearErrors() {
    this.resetErrors()
    clearErrors(this.errorTargets, this.inputTargets)
  }

  resetForm() {
    this.resetErrors()
    resetErrorTargets()
    resetInputTargets()
  }

  onModalClose() {
    this.resetForm()
  }

  revealError(error) {
    const errorObj = this.getError(error)
    if (errorObj) {
      setError(errorObj)
    }
  }

  revealErrors() {
    const errors = this.errors

    // Iterate over errors and reveal each error found
    errors.forEach((e) => this.revealError(e))

    // Find the first error in the errors array
    const firstError = errors[0]
    if (firstError) {
      // Set focus on the input associated with the first error
      const inputTarget = this.getError(firstError)?.inputTarget
      if (inputTarget) {
        setFocus(inputTarget)
      }
    }
  }

  // validation functions

  validateName() {
    if (isEmpty(this.nameTarget.value.trim())) {
      this.addError("empty-name")
    }
  }

  validateCheckbox(target, error) {
    if (!isChecked(target)) {
      this.addError(error)
    }
  }

  validateEmail() {
    const emailTarget = this.emailTarget
    const emailErrorTarget = this.emailErrorTarget
    const email = emailTarget.value.substr(0, Constants.EMAIL_LENGTH).toLowerCase()
    if (isEmailValid(email)) {
      fetch(`/api/users/username/validate?username=${encodeURIComponent(email)}`, {
        method: "get"
      })
        .then((response) => response.status === 200 && response.json())
        .then((data) => {
          if (data !== true) {
            clearError(emailTarget, emailErrorTarget)
          } else {
            this.addError("non-unique-email")
          }
        })
        .catch(() => {
          emailErrorTarget.innerHTML("Something went wrong. Please try to validate your email again.")
        })
    } else {
      this.addError("invalid-email")
    }
  }

  validatePassword() {
    const p = {password: this.passwordTarget.value}

    if (isPasswordTooShort2(p)) {
      this.addError("short-password")
    } else if (isInvalidPassword(p)) {
      this.addError("invalid-password")
    }
  }

  cleanedPhoneNumber() {
    const phoneValue = this.hasPhoneNumberTarget ? this.phoneNumberTarget.value : "";
    return phoneValue.trim().replace(/[^0-9]/g, "");
  }

  validatePhone() {
    const phoneNumber = this.cleanedPhoneNumber()

    // phone number is optional, check if there is a value and if it is valid
    if (phoneNumber.length > 0 && !isPhoneNumberValid(phoneNumber)) {
      this.addError("invalid-phone")
    }
  }

  validateState() {
    // NOTE: this is not *state* state, this is the state of residence for the user
    if (this.hasStateTarget && isEmpty(this.stateTarget.value)) {
      this.addError("empty-state");
    }
  }


  validateForm() {
    this.validateName()
    this.validateEmail()
    this.validatePassword()
    this.showPhone && this.validatePhone()
    this.showState && this.validateState()
    this.validateCheckbox(this.olderTarget, "older-unchecked")
    this.validateCheckbox(this.agreeTarget, "agree-unchecked")
  }

  // Signup form submission
  onSubmit(e) {
    e.preventDefault()
    const email = this.emailTarget.value
    const password = this.passwordTarget.value
    const submitButton = this.submitTarget

    // clear errors for fresh check
    this.clearErrors()

    // check for errors
    this.validateForm()

    // if there are errors, reveal them
    if (this.errors.length > 0) {
      this.revealErrors()
    } else {
      // continue with signup request
      this.loginEmailTarget.value = email
      this.loginPasswordTarget.value = password
      fetch("/api4/direct/signup", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          user: {
            username: email,
            password: password,
            birthdate: "02-11-1820",
            name: this.nameTarget.value,
            language: "en",
            first_name: "",
            last_name: "",
            state_code: this.hasStateTarget && this.stateTarget.value,
            phone_number: this.cleanedPhoneNumber()
          },
          subdomain: getMetaContentByName("subdomain")
        })
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              // disable submit button and submit form
              toggleSubmitButton(submitButton, true)
              this.loginFormTarget.submit()
              break
            case 422:
              if (response.body.error === "not-unique") {
                this.revealError("non-unique-email")
                toggleSubmitButton(submitButton, false)
              } else {
                this.revealError("try-again")
                toggleSubmitButton(submitButton, false)
                throw new Error("Error on direct signup for user with 422.")
              }
              break
            default:
              this.revealError("try-again")
              toggleSubmitButton(submitButton, false)
              throw new Error("Error on signup for user " + email + ": (" + response.statusCode + ") " + response.statusText)
          }
        })
        .catch((err) => {
          throw new Error("Unknown error on completing direct signup:" + err)
        })
    }
  }
}
